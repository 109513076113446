window.site = window.site || {};

site.template = (function($, _, Mustache) {
  site.templates = site.templates || {};
  site.translations = site.translations || {};

  var defaults = {
    globals: {
      t: site.translations,
      variables: {
        // IE doesn't support location.origin, so...
        site_url: window.location.protocol + '//' + window.location.hostname
      }
    }
  };

  // include config settings from brand common module
  if (!_.isUndefined(Drupal) && !_.isUndefined(Drupal.settings) && !_.isUndefined(Drupal.settings.common)) {
    $.extend(defaults.globals.variables, Drupal.settings.common);
  }

  var public = {
    get: function(args) {
      var template = site.templates[args.name];

      // If that didn't work, search for a versioned match of the same template
      // (eg. template_v2)
      if (!template && args.name) {
        for (var key in site.templates) {
          if (site.templates.hasOwnProperty(key)) {
            var matcher = new RegExp(args.name + '_v(\\d+)$');
            if (matcher.test(key)) {
              template = site.templates[key];
              break;
            }
          }
        }
      }

      if (_.isUndefined(template)) {
        console.log('The template ' + args.name + ' cannot be found');
      }

      var rendered = this.render(template, args.data);

      if (_.isFunction(args.callback)) {
        var so = args.callback(rendered);
        if (!_.isUndefined(so)) {
          return so;
        }
      }

      return rendered;
    },

    render: function(template, data) {
      defaults.globals.t = site.translations;
      data = data || {};

      // You can pass just the template as a string if you want:
      if (_.isString(template)) {
        template = {
          content: template,
          data: {}
        };
      }

      var view = $.extend({}, defaults, template.data, data);
      var partials = {};

      if (!_.isUndefined(template.partials)) {
        $.each(template.partials, function(key, name) {
          if (_.isUndefined(site.templates[key]) && _.isUndefined(site.templates[name])) {
            console.log('The partial ' + key + ' or ' + name + ' cannot be found');
          }

          var pkey = !_.isUndefined(site.templates[key]) ? key : name;
          partials[pkey] = site.templates[pkey].content;
        });
      }

      return Mustache.render(template.content, view, partials);
    }
  };

  return public;
})(
  window.jQuery = window.jQuery || function() {},
  window._ = window._ || {},
  window.Mustache = window.Mustache || {}
);

(function($, generic) {
  // Route the old perlgem overlay method to colorbox:
  generic.overlay = {
    launch: function(args, event) {
      if (typeof event !== 'undefined') {
        event.preventDefault();
      }
      // ColorBox args sent along
      var cboxArgs = {
        'height': 'auto',
        'width': '768px',
        'margin': 'auto'
      };
      // Smoosh in any overrides from other calls, looks like args.cssStyle
      _.extend(cboxArgs, args);
      _.extend(cboxArgs, args.cssStyle); // get height/width overrides
      // When mobile, override any height/width and set to 100%
      if ($(window).width() <= 768) {
        _.extend(cboxArgs, {height: '100%', width: '100%'});
      }
      // Actual content of the overlay
      if (typeof args.content !== 'undefined') {
        cboxArgs.html = args.content;
      }
      // A custom class each launcher has the option of setting
      if (typeof args.cssClass !== 'undefined') {
        cboxArgs.className = args.cssClass;
      }
      // Scroll to an anchor, if sent over
      if (typeof args.inPageAnchor !== 'undefined') {
        cboxArgs.onComplete = function() {
          $('#cboxLoadedContent').scrollTo($('#' + args.inPageAnchor), 50);
        };
      }
      // Launch it
      $.colorbox(cboxArgs);
    },

    initLinks: function() {
      // Give us access to the parent scope so we can hit .launch()
      var self = this;
      // Links are tiggered via class, but indicate if already processed
      var $triggers = $('.overlay-link:not(.overlay-ready)').addClass('overlay-ready');

      // Depending on the type of link, the overlay needs to do something unique
      $triggers.each(function() {
        var args = {
              cssStyle: {}
            }, // args sent to overlay
            linkClassNames = $(this).attr('class'), // class name sent to colorbox
            linkHref = $(this).attr('href'), // actual href
            linkHrefWithEmbed = linkHref,
            inPageAnchor = $(this).data('inpage-anchor'), // see try/catch below
            overlayElement = $(this).data('overlay-content'); // use an existing element as content

        // used in overlay linking below
        var urlParts = document.createElement('a'); //
        urlParts.href = linkHref; //

        // Parse height options out of the link's class
        var widthRegexResults = linkClassNames.match(/overlay-width-(\d+)/);
        if (widthRegexResults) {
          args.cssStyle.width = widthRegexResults[1];
        }
        // Parse width options
        var heightRegexResults = linkClassNames.match(/overlay-height-(\d+)/);
        if (heightRegexResults) {
          args.cssStyle.height = heightRegexResults[1];
        }
        // Add a custom class, optionally
        var cssClassRegexResults = linkClassNames.match(/overlay-addclass-([a-z\-\_]+)/);
        if (cssClassRegexResults) {
          args.className = cssClassRegexResults[1];
        }

        // Make sure embed doesn't already exist. This gets added form internal
        // drupal embeddable urls
        if (typeof overlayElement !== 'undefined') {
          args.content = $(overlayElement).html();
        } else {
          try {
            if (!linkHref.match(/[\&\?]embed=1($|&)/)) {
              linkHrefWithEmbed = urlParts.pathname + (urlParts.search === '' ? '?' : urlParts.search + '&') + 'embed=1' + urlParts.hash;
              linkHrefWithEmbed = (linkHrefWithEmbed.charAt(0) != linkHref.charAt(0) && linkHref.charAt(0) == '/' ? '/' : '') + linkHrefWithEmbed;
              // Retain original link if it included the protocol.
              if (linkHref.match(/https?:\/\//)) {
                linkHrefWithEmbed = urlParts.protocol + '//' + urlParts.host + linkHrefWithEmbed;
              }
            }
          } catch (e) {
            linkHrefWithEmbed = linkHref;
          }

          // Fix the link within the page
          $(this).attr('href', linkHrefWithEmbed);
          // But this is actually used to launch overlay
          args.href = linkHrefWithEmbed;
        }

        // scrollTo behavior if we have a data attribute
        if (typeof inPageAnchor !== 'undefined') {
          args.inPageAnchor = inPageAnchor;
        }

        // Launch a colorbox overlay
        $(this).on('click', function(e) {
          // use our canonical launch function for all the goodies
          self.launch(args, e);
        });
      }); // .each()
    }, // initLinks

    hide: function() {
      $.colorbox.close();
    },

    getRBKeys: function() {
      generic.rb.language = generic.rb('language');
      generic.rb.language.rb_close = generic.rb.language.get('close');
    }
  };

  $(function() {
    generic.overlay.getRBKeys();
    generic.overlay.initLinks();
  });
})(jQuery, window.generic || {});

(function($, generic, site) {
  /**
   * Method to grab a cookie and use that to control DOM elements as needed.
   * Handles the setting and getting of the user cookie defined in cookie.name and set in backend.
   * To find where the cookie is set on backend, look to Request::TransactionLocaleHandler.
   * Example cookie structure not signed in:
   * FE_USER_CART=item_count:1&first_name:&signed_in:0&region_id:0
   * Example cookie structure signed in:
   * FE_USER_CART=item_count:3&first_name:John&signed_in:1&region_id:0
   * You can set specific functions on page load using events.load or hook into javascript events
   * by defining them in the events class and adding the event to events.init.
   * The cookie class is used to handle all the cookie functionality such as the setting and getting.
   * This method is meant to be stand alone so should be able to add to a brand without many tweaks.
   * Uses straight javascript so not dependent on a javascript framework except for DOM load.
   * Preferably added to the where ever the globalnav javascript is added within a brand.
   * @memberOf site
   */
  site.userInfoCookie = function() {
    // Set global vars here.
    var nodes = {};
    /**
     * Private internal cookie class.
     * Leverages generic.cookie to get and set the cookie values.
     */
    var cookie = {
      name: 'FE_USER_CART',
      value: '',
      regEx: function(key) {
        if (!key) {
          return null;
        }
        return new RegExp(key + ':([^;&,}]*)');
      },
      set: function() {
        if (!this.name) {
          return null;
        }
        var userCookie = generic.cookie(this.name);
        this.value = userCookie ? userCookie : '';
      },
      getValue: function(key) {
        var keyVal = this.value.match(this.regEx(key));
        return keyVal ? keyVal[1] ? keyVal[1] : null : null;
      },
      setValue: function(key, val) {
        var match = this.value.match(this.regEx(key));
        var oldValue = match[0];
        var newValue = this.value.replace(match[1], val);
        generic.cookie(this.name, newValue, {
          path: '/'
        });
        this.value = newValue;
      }
    };
    /**
     * Private events class that handles all individual events.
     * Add all events in 'init' method so they will get fired on page load.
     * The cart event is commented out but there as an example.
     */
    var events = {
      init: function() {
        this.load();
      },
      load: function() {
        _setCartItemsTotal();
      },
      cart: function() {}
    };

    // Additional helper functions below here.

    // Pulls in the cookie info and updates the DOM;
    var _setCartItemsTotal = function() {
      if (!nodes.cartTotalContainer) {
        return null;
      }

      var valueKey = 'item_count';
      var itemsTotal = cookie.getValue(valueKey) - 0 || 0;
      $.each(nodes.cartTotalContainer, function() {
        $(this).html(itemsTotal || '');
      });
    };
    /**
     * BRAND SPECIFIC: Get any DOM nodes and assign them to the global class var nodes.
     * Varies between brands.
     * Helps keep all the brand specific DOM definitions in one spot.
     */
    var _getDomNodes = function() {
      nodes.cartTotalContainer = $('.js-cart_count');
    };

    return {
      init: function() {
        _getDomNodes();
        cookie.set();
        events.init();
      },
      set: function() {
        cookie.set();
      },
      getValue: function(key) {
        return cookie.getValue(key);
      },
      setValue: function(key, val) {
        cookie.setValue(key, val);
      }
    };
  }();
  /**
   * Set the cookie outside of $(document).ready
   * so other scripts can always access it in their $(document).ready:
   */
  site.userInfoCookie.set();
})(jQuery, window.generic || {}, window.site || {});
var generic = generic || {};
(function($) {
  Drupal.behaviors.countryChooser = {
    updateSelect: function($select) {
      var $localeName = this.getUrlParameter('LOCALE');
      var $storeCookie = this.getUrlParameter('stores');
      var $storeOption;
      var $isJpPage = window.location.href.indexOf('jp');
      if ($localeName === 'en_US') {
        if ($storeCookie === '1') {
          if ($isJpPage > -1) {
            $storeOption = $select.find('option[value*="jp"]');
          } else {
            $storeOption = $select.find('option[value*="LOCALE=en_US&stores=1"]:not([value*="jp"])');
          }
          $storeOption.prop('selected', true);
        }
      }
    },
    attach: function(context, settings) {
      var $select = $('.country-chooser__select', context);

      $select.on('change', function(obj) {
        window.location = obj.target.value;
      });

      if (window.location.search.indexOf('stores') > -1 || generic.cookie('stores')) {
        generic.cookie('stores', 1, {'path': '/'});
        $('body').addClass('stores');
        this.updateSelect($select);
      }

      if (window.location.search.indexOf('online') > -1) {
        generic.cookie('stores', 0, {'path': '/'});
      }
    },
    getUrlParameter: function(parameter) {
      parameter = parameter.replace(/[\]]/, '\\]');
      var regex = new RegExp('[\\?&]' + parameter + '=([^&#]*)');
      var results = regex.exec(location.search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ''));
    }
  };
})(jQuery);

// This file includes this commit to work with require.js:
// https://github.com/pouipouidesign/Unison/commit/30b1b0d9fcff831f7c4f92952911902f01284b57
// It also has some customizations to work with IE8.

/* global Unison: true */
Unison = (function() {
  'use strict';

  var win = window;
  var doc = document;
  var head = doc.head;
  var eventCache = {};
  var unisonReady = false;
  var currentBP;

  var util = {
    parseMQ: function(el) {
      var str = this.getStyleProperty(el, 'font-family');
      return str.replace(/"/g, '').replace(/'/g, '');
    },
    getStyleProperty: function(el, attr) {
      if (this.isUndefined(win.getComputedStyle)) {
        attr = attr.replace(/-(.)/g, function(match, group1) {
          return group1.toUpperCase();
        });
        return el.currentStyle[attr];
      } else {
        return win.getComputedStyle(el, null).getPropertyValue(attr);
      }
    },
    debounce: function(func, wait, immediate) {
      var timeout;
      return function() {
        var context = this, args = arguments;
        clearTimeout(timeout);
        timeout = setTimeout(function() {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        }, wait);
        if (immediate && !timeout) {
          func.apply(context, args);
        }
      };
    },
    isObject: function(e) {
      return typeof e === 'object';
    },
    isUndefined: function(e) {
      return typeof e === 'undefined';
    }
  };

  var events = {
    on: function(event, callback) {
      if (!util.isObject(eventCache[event])) {
        eventCache[event] = [];
      }
      eventCache[event].push(callback);
    },
    emit: function(event, data) {
      if (util.isObject(eventCache[event])) {
        var eventQ = eventCache[event].slice();
        for (var i = 0; i < eventQ.length; i++) {
          eventQ[i].call(this, data);
        }
      }
    }
  };

  var breakpoints = {
    all: function() {
      var BPs = {};
      var allBP = util.parseMQ(doc.querySelector('title')).split(',');
      for (var i = 0; i < allBP.length; i++) {
        var mq = allBP[i].trim().split(' ');
        BPs[mq[0]] = mq[1];
      }
      return unisonReady ? BPs : null;
    },
    now: function(callback) {
      var nowBP = util.parseMQ(head).split(' ');
      var now = {
        name: nowBP[0],
        width: nowBP[1]
      };
      return unisonReady ? util.isUndefined(callback) ? now : callback(now) : null;
    },
    update: function() {
      breakpoints.now(function(bp) {
        if (bp.name !== currentBP) {
          events.emit(bp.name);
          events.emit('change', bp);
          currentBP = bp.name;
        }
      });
    }
  };

  if (util.isUndefined(head)) {
    head = document.getElementsByTagName('head')[0];
  }

  win.onresize = util.debounce(breakpoints.update, 100);

  // if (document.readyState === "complete" || document.readyState === "loaded" || document.readyState === "interactive") {
  unisonReady = util.getStyleProperty(head, 'clear') !== 'none';
  breakpoints.update();
  // } else {
  //   doc.addEventListener('DOMContentLoaded', function(){
  //     unisonReady = util.getStyleProperty(head, 'clear') !== 'none';
  //     breakpoints.update();
  //   });
  // }

  return {
    fetch: {
      all: breakpoints.all,
      now: breakpoints.now
    },
    on: events.on,
    emit: events.emit,
    util: {
      debounce: util.debounce,
      isObject: util.isObject
    }
  };
})();

/**
 * @namespace
 */
var site = site || {};
site.signin = site.signin || {};

(function($, site) {
  Drupal.behaviors.forgot_password = {
    attach: function(context) {
      /**
       * One-time call to collect specific RB Keys used for forget password.
       * @methodOf site.signin
       */

      site.signin.getRBKeys = function() {
        site.signin.rb = generic.rb('error_messages');
        site.signin.forgotPasswordEmailNotFound = site.signin.rb.get('incorrect_pwremind');
        site.signin.forgotPasswordNoEmailProvided = site.signin.rb.get('session_pw_hint');
        site.signin.forgotPasswordMigratedUser = site.signin.rb.get('migrated.mobile_account.signin');
      };

      /**
       * This method is used to set up the forget password functionality
       * on the site.
       * Takes the passed element in the DOM and gets the required form
       * nodes and places them within forgotPassArgs object.
       * site.signin.setForgetPassword() is then called if the param resetPassword
       * is set to true.
       * @param {Object} args
       * @param {Object} args.emailNode **REQUIRED** DOM element of either a
       * form element or wrapper element of the email.
       * @param {Object} args.errorListNode **REQUIRED** DOM element used to show
       * password hint or error messaging if hint is not available.
       * @param {Object} args.forgotPasswordLink **REQUIRED** DOM element of the
       * forget password link.
       * @params {element} forget link node set on dom:load
       * @methodOf site.signin
       */
      site.signin.forgotPassword = function(args) {
        if ((args.emailNode.length > 1) || !args.forgotPasswordLink || !args.errorListNode) {
          return null;
        }

        site.signin.getRBKeys();

        var errorListNode = args.errorListNode;
        var emailNode = args.emailNode;
        var forgotPasswordLink = args.forgotPasswordLink;
        var forgotPasswordNote = args.forgotPasswordNote;
        var forgotPasswordCopy = $('#lpw-text');
        // Content may have been set on server side. If so, do not hide.
        if (forgotPasswordCopy.length > 1 && forgotPasswordCopy.html().length < 1) {
          forgotPasswordCopy.hide();
        }

        forgotPasswordLink.bind('click', function(evt) {
          evt.preventDefault();

          forgotPasswordCopy.show();
          var email = site.signin.getEmailAddress(emailNode);

          if (email.length < 1) {
            $('.error_messages').empty();
            $('.error_messages').append("<li class='s' id='signin_error.email_address.'>" + site.signin.forgotPasswordNoEmailProvided + '</li>');
            emailNode.addClass('error');

            // Remove errors from new user section div
            $('#new-account > .error_messages').empty();

            return null;
          }

          site.signin.requestPassword(email);

          // Remove errors from new user section div
          $('#new-account > .error_messages').empty();
          return false;
        });
      };

      /**
       * This method is used to reset a users password by submitting a hidden form.
       * @param {email} the user's email address **REQUIRED**
       * @param {actionURL} the page URL of the reset page **REQUIRED**
       * **NOTE**: The error check for if an account exists is handled by the password
       * hint function. The reset is hidden inside the password hint function
       * so no duplicate error checking is needed here.
       */
      site.signin.initResetPassword = function(emailNode) {
        // Have to initialise the link here because it isn't on the page until the pw hint method is invoked
        var email = site.signin.getEmailAddress(emailNode);
        var resetPassLink = $('#pwd-reset');
        if (resetPassLink) {
          resetPassLink.bind('click', function(evt) {
            evt.preventDefault();
            site.signin.requestPassword(email);
          });
        }
      };

      /**
       * This method is used to direct the user to registration.tmpl or password_request.tmpl.
       * The passed values are injected into the genric form before it is submitted.
       * @param {email} the user email that will be passed. **REQUIRED**
       * @param {actionURL} action url used on user submit. **REQUIRED**
       * @param {returnURL} passed when an action is needed after the user
       * has gone to the next template page. **NOT REQUIRED**
       * Example case for returnURL is if the user goes through checkout and registers,
       * the returnURL is used to pass the viewbag action url to the registration page. Once
       * registration form is filled out, user will be brought to viewbag.
       * @methodOf site.signin
       */
      site.signin.submitHiddenSigninForm = function(args) {
        if (!args.actionURL || !site.signin.hiddenForm) {
          return null;
        }
        site.signin.hiddenForm.attr('action', args.actionURL);
        var hiddenEmailNode = $('.js-hidden-email');
        hiddenEmailNode.val(args.email);

        if (args.returnURL) {
          var hiddenReturnNode = $('.js-hidden-return');
          hiddenReturnNode.val(args.returnURL);
        }
        site.signin.hiddenForm.submit();
      };

      /**
       * This method is used to call site.signin.submitHiddenSigninForm by
       * passing the user's email used in the reset form submit.
       * @param {String} the user email that will be passed. **REQUIRED**
       * @methodOf site.signin
       */
      site.signin.requestPassword = function(emailAddr) {
        site.signin.hiddenForm = $('.js-signin-hidden-form');
        if (site.signin.hiddenForm) {
          site.signin.submitHiddenSigninForm({
            email: emailAddr,
            actionURL: '/account/password_request.tmpl'
          });
        }
      };

      /**
       * This method is used to pull the user's email from either a form
       * input or container html tag wrapper (i.e. div, span, etc)
       * @param {String} emailNode the user email that will be passed. **REQUIRED**
       * @methodOf site.signin
       */
      site.signin.getEmailAddress = function(emailNode) {
        if (!emailNode) {
          return null;
        }
        return emailNode.val();
      };
    }
  };
})(jQuery, site);
var prodcat = prodcat || {};
prodcat.data = prodcat.data || {};

var site = site || {};
site.onLoadRpc = site.onLoadRpc || {};
site.onLoadRpc.requests = site.onLoadRpc.requests || [];

(function($) {
  prodcat.data.collectProductIds = function($context) {
    var prodIds = [];
    $('[data-product-id]', $context).each(function() {
      var $this = $(this);
      var prodId = $this.attr('data-product-id');
      // Check if a prodId exists
      if (!prodId) {
        return null;
      }
      var insert = true;
      for (var i = prodIds.length - 1; i > -1; i--) {
        if (prodIds[i] === prodId) {
          insert = false;
          break;
        }
      }
      if (insert) {
        prodIds.push(prodId);
      }
      insert = true;
    });
    return prodIds;
  };

  /**
   * Retrieves product data from data store. Folds full sku data into product.skus array
   * @param {String} prodId
   */
  prodcat.data.getProduct = function(prodId) {
    if (!prodcat.data.store.products[prodId]) {
      return null;
    }
    var prodData = $.extend(true, {}, prodcat.data.store.products[prodId]);
    _.each(prodData.skus, function(skuId, idx) {
      prodData.skus[idx] = prodcat.data.getSku(skuId);
    });

    return prodData;
  };

  prodcat.data.getSku = function(skuId) {
    skuId = skuId + ''; // Has to be a string to run indexOf
    skuId = skuId.indexOf('SKU') === 0 ? skuId : 'SKU' + skuId;
    var skuData = prodcat.data.store.skus[skuId];
    if (!skuData) {
      return null;
    }

    return skuData;
  };

  // Sanitize the product data to ensure a consistent data structure
  prodcat.data.sanitizeProductData = function(product) {
    // Force the following fields to be an array even if they're originally a string
    var pf = ['IMAGE_L', 'IMAGE_M', 'IMAGE_S', 'IMAGE_XL', 'IMAGE_XM', 'IMAGE_XXL'],
      fk = '';
    for (var i = pf.length; i--;) {
      fk = pf[i];
      if (_.isUndefined(product[fk])) {
        continue;
      }
      product[fk] = typeof product[fk] === 'string' ? [product[fk]] : product[fk];
    }

    return product;
  };

  $(document).on('prodcat.products.update', function(e, productsArray, deepCopy) {
    prodcat.data.updateProducts(productsArray, deepCopy);
  });

  prodcat.data.updateProducts = function(productsArray, deepCopy) {
    var self = this;
    deepCopy = deepCopy === false ? deepCopy : true; // Do a deep copy of the product data by default
    _.each(productsArray, function(newProd) {
      if (!newProd) {
        return;
      }
      var targetProd = $.extend(deepCopy, {}, newProd);
      var oldProd = prodcat.data.store.products[newProd.PRODUCT_ID];

      if (_.isArray(newProd.skus)) {
        prodcat.data.updateSkus(targetProd.skus);
        targetProd.skus = _.map(targetProd.skus, function(sku) {
          return sku.SKU_ID;
        });
      }

      var prod = prodcat.data.sanitizeProductData(_.isObject(oldProd) ? _.extend(oldProd, targetProd) : targetProd);

      // Set defaultSku to the first sku if not already set:
      if (_.isUndefined(prod.defaultSku) && prod.skus && prod.skus.length) {
        prod.defaultSku = self.getSku(prod.skus[0]);
      }

      prodcat.data.store.products[targetProd.PRODUCT_ID] = prod;
    });

    $(document).trigger('prodcat.products.updated', prodcat.data.store.products);
  };

  // Sanitize the sku data to ensure a consistent data structure
  prodcat.data.sanitizeSkuData = function(sku) {
    // Remove any "product" keys from the sku object to prevent recursion errors down the road.
    sku.product = undefined;

    // Force the following fields to be an array even if they're originally a string
    var sf = ['IMAGE_SMOOSH_L', 'IMAGE_SMOOSH_S', 'IMAGE_SMOOSH_XL'],
      fk = '';
    for (var i = sf.length; i--;) {
      fk = sf[i];
      if (_.isUndefined(sku[fk])) {
        continue;
      }
      sku[fk] = typeof sku[fk] === 'string' ? [sku[fk]] : sku[fk];
    }

    return sku;
  };

  prodcat.data.updateSkus = function(skusArray) {
    _.each(skusArray, function(newSku) {
      newSku = prodcat.data.sanitizeSkuData(newSku);
      var oldSku = prodcat.data.store.skus[newSku.SKU_ID];
      prodcat.data.store.skus[newSku.SKU_ID] = _.isObject(oldSku) ? _.extend(oldSku, newSku) : newSku;
    });
  };

  prodcat.data.init = function() {
    prodcat.data.store = {
      categories: {},
      products: {},
      skus: {}
    };

    if (typeof page_data === 'undefined' || !page_data) {
      return null;
    }

    function _catStore(newCat) {
      var oldCat = prodcat.data.store.categories[newCat.CATEGORY_ID];
      var targetCat = $.extend(true, {}, newCat);
      if (_.isArray(targetCat.products)) {
        prodcat.data.updateProducts(targetCat.products);
      }
      targetCat.products = _.map(targetCat.products, function(prod) {
        return prod.PRODUCT_ID;
      });
      prodcat.data.store.categories[targetCat.CATEGORY_ID] = _.isObject(oldCat) ? _.extend(oldCat, targetCat) : targetCat;
    }

    for (var key in page_data) {
      if (!page_data[key]) {
        continue;
      }
      if (page_data[key].categories && _.isArray(page_data[key].categories)) {
        _.each(page_data[key].categories, _catStore);
      }
      if (page_data[key].products && _.isArray(page_data[key].products)) {
        prodcat.data.updateProducts(page_data[key].products);
      }
      if (page_data[key].product && _.isObject(page_data[key].product)) {
        $(document).trigger('prodcat.products.update', [page_data[key].product]);
        prodcat.data.updateProducts([page_data[key].product]);
      }
    }

    $(document).trigger('prodcat.data.initalized', prodcat.data.store);
  };

  prodcat.data.pids = prodcat.data.collectProductIds();
  // Prevent making an empty call if no prodIds are available.
  if (prodcat.data.pids.length > 0) {
    site.onLoadRpc.requests.push({
      method: 'prodcat.querykey',
      getParams: function() {
        var pids = prodcat.data.pids;
        return [{
          products: pids,
          query_key: 'catalog-mpp-volatile'
        }];
      },
      onSuccess: function(r) {
        if (
          !r ||
          !r.result ||
          !r.result.value ||
          !r.result.value.products
        ) {
          return;
        }

        var prods = _.compact(r.result.value.products);
        prodcat.data.updateProducts(prods);

        _.each(prods, function(prod) {
          var prodSlctr = "[data-product-id='" + prod.PRODUCT_ID + "']";
          $(prodSlctr).trigger('inv_status_data:updated');
        });

        if (prods.length > 0) { // Can't think of a reason why this WOULD be empty, but check, just in case
          /**
           * Trigger a custom event,
           * letting all who care know that we've updated inventory status data for every product
           */
          $(document).trigger('inv_status_data:finished');
        }

        $(document).trigger('prodcat.data.query.success');
      }
    });
  }

  prodcat.data.isPaletteMultiSku = function(prodId) {
    var prodData = prodcat.data.getProduct(prodId);
    return !!prodData.isPaletteMultiSku;
  };

  // Pull data from page_data and store internally.
  Drupal.behaviors.prodcatDataInit = {
    attached: false,
    attach: function() {
      if (!this.attached) {
        prodcat.data.init();
      }
      this.attached = true;
    }
  };
})(jQuery);
var prodcat = prodcat || {};
prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

(function($, generic) {
  var storesButton = site.translations.product.stores_button || 'Find in Store';
  var storesPath = site.translations.product.stores_path || '/about#stores';

/**
 * Adds SKUs to cart.
 */
prodcat.ui.addToCart = function(args) {
  var skuBaseId;
  if (args.skuData && args.skuData.SKU_BASE_ID) {
    skuBaseId = args.skuData.SKU_BASE_ID;
  } else if (args.skuBaseId) {
    skuBaseId = args.skuBaseId;
  } else {
    return;
  }

  var quantity;
  if (typeof args.quantity !== 'undefined') {
    quantity = args.quantity;
  } else {
    quantity = 1;
  }

  var catBaseId = '';
  if (args.skuData && args.skuData.PARENT_CAT_ID) {
    var matchResult = args.skuData.PARENT_CAT_ID.match("[0-9]+");
    if (matchResult) {
      catBaseId = matchResult[0];
    }
  }

  args.skus       = args.skus || (_.isString(skuBaseId) ? [skuBaseId] : skuBaseId);
  args.itemType   = args.itemType || 'cart';
  args.QTY        = quantity || args.QTY;
  args.INCREMENT  = 1;  // INCREMENT only needs to be true.

  // Conditionally add a CAT_BASE_ID key to the list of parameters to send
  var cbid = args.CAT_BASE_ID || catBaseId;
  if (cbid.length > 0) {
    args.CAT_BASE_ID = cbid;
  }

  generic.checkout.cart.updateCart({
    params: args,
    onSuccess: function(r) {
      var resultData = r.getData();
      $(document).trigger('addToCart.toggle', [args.$addBtn]);
      $(document).trigger('addToCart.success', [resultData]);
    },
    onFailure: function(ss) {
      var errorObjectsArray = ss.getMessages();
      $(document).trigger('addToCart.toggle', [args.$addBtn]);
      $(document).trigger('addToCart.failure', [errorObjectsArray]);
    }
  });
};

/**
 * Adds a SKU to the user's primary favorites list.
 * @param {Object} args
 * @param {Object} args.skuData a set of key-value pairs describing a SKU
 * @param {String, Number} args.skuData.SKU_BASE_ID Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
 * @param {String} args.skuData.PARENT_CAT_ID Category ID for the SKU
 * @param {String, Number} args.skuBaseId Base ID for a SKU (numerical only, i.e. no 'SKU' prefix)
 */
prodcat.ui.addToFavorites = function(args) {

  var params = {
    _SUBMIT: 'alter_collection',
    action: 'add'
  };

  var skuBaseId;
  if (args.skuData && args.skuData.SKU_BASE_ID) {
    skuBaseId = args.skuData.SKU_BASE_ID;
  } else if (args.skuBaseId) {
    skuBaseId = args.skuBaseId;
  } else {
    return;
  }
  params.SKU_BASE_ID = skuBaseId;

  if (args.skuData && args.skuData.PARENT_CAT_ID) {
    var matchResult = args.skuData.PARENT_CAT_ID.match("[0-9]+");
    if (matchResult) {
      params.CAT_BASE_ID = matchResult[0];
    }
  }

  generic.jsonrpc.fetch({
    method : 'rpc.form',
    params: [params],
    onSuccess:function(jsonRpcResponse) {
      var d = jsonRpcResponse.getData();
      var r = d.ac_results[0].result;
      var cr;

      if (r.KEY === 'SKU_ALREADY_IN_COLLECTION.ADD_SKU.COLLECTION.SAVE') {
        cr = jsonRpcResponse.getCartResults();
        $(document).trigger('addToWishlist.exists', [cr, args.$el]);

      }
      else if (r.SUCCESS === 1 || r.KEY === 'SUCCESS.ADD_SKU.COLLECTION.SAVE') {
        cr = jsonRpcResponse.getCartResults();
        $(document).trigger('addToWishlist.success', [cr, args.$el]);
      }
    },
    onFailure: function(ss) {
      var errorObjectsArray = ss.getMessages();
      $(document).trigger('addToWishlist.failure', [errorObjectsArray]);
    }
  });
};

prodcat.ui.grids = (function () {
  var grids = [];
  return $.extend(prodcat.ui.grids || {}, {
    add: function (grid) {
      if (_.indexOf(grids, grid) === -1) { // if we can't find the grid in our list
        grids.push(grid);
      }
    },
    clean: function (args) {
      if (args.before && typeof args.before === "function") {
        args.before(grids);
      }
      site.util.grids.attach(grids);
      if (args.after && typeof args.before === "function") {
        args.after(grids);
      }
      grids = [];
    }
  });
}());

// Default product listeners:

$(document).on('product.init', '.js-product', function() {
  var $product = $(this);
  var prodData = prodcat.data.getProduct($product.data('product-id'));
  var routePrefix = (!!prodData && prodData.shaded) ? '/shade/' : '/sku/';
  var hash = location.hash,
      routeStringAfter = hash.split('#' + routePrefix)[1];
  var isSPP = $product.hasClass('product-full');
  var isQuickShop = $product.hasClass('js-quickshop');
  var skuBaseId;
  if (prodData && !!prodData.is_kit && hash) {
    return;
  }
  // Check for sku routing in the SPP url
  if (isSPP) {
    var hash = location.hash,
      routeStringAfter = hash.split('#' + routePrefix)[1];
    skuBaseId = (/^\d+$/.test(routeStringAfter)) ? routeStringAfter : '';
  } else {
    skuBaseId = $product.attr('data-sku-base-id') ? $product.attr('data-sku-base-id') : $product.data('sku-base-id');
  }

  try {
    Drupal.behaviors.selectBox.attach(this);
  } catch(e) {
    console.log('Drupal.behaviors.selectBox.attach failed in product.init.');
  }

  if (!skuBaseId && !!prodData && (isQuickShop || (isSPP && prodData.skus.length == 1))) {
    if (prodData.defaultSku) {
      skuBaseId = prodData.defaultSku.SKU_BASE_ID;
    }

    // if defaultSku is Sold Out, pick first shoppable sku
    if (!prodData.defaultSku.isShoppable && prodData.skus.length > 1) {
      var validSku = _.findWhere(prodData.skus, {isShoppable: 1});
      skuBaseId = (!!validSku) ? validSku.SKU_BASE_ID : skuBaseId;
    }
  }

  var routerAction = {
    changeSku: function(skuID) {
      if (!isSPP) {
        return;
      }
      var skuData = prodcat.data.getSku(skuID);
      if (skuData) {
        skuBaseId = skuID;
      }
    },
    changeSppShade: function(shadeName) {
      if (!isSPP) {
        return;
      }

      routeNoSpaces = decodeURIComponent(shadeName).split('_').join(' ');

      _.each(prodData.skus, function(sku) {
        if (routeNoSpaces === sku.SHADENAME) {
          skuBaseId = sku.SKU_BASE_ID;
        }
      });
    }
  };

  var routes;
  if (!!prodData && prodData.shaded) {
    routes = {
      '/shade/:shadeName' : routerAction.changeSppShade
    };
  } else {
    routes = {
      '/sku/:skuID' : routerAction.changeSku
    };
  }

  var router = Router(routes);
  router.init();

  if (!!skuBaseId) {
    $(this).trigger('product.skuSelect', [skuBaseId]);
  } else {
    // update inventory status:
    $(this).trigger('product.updateInvStatus');
  }
});

$(document).on('product.updateInvStatus', '.js-product', function() {
  var $addBtn = $('.js-add-to-cart, .js-add-to-bag', this);
  var $waitlistBtn = $('.js-add-to-waitlist', this);
  var skuBaseId = $addBtn.data('sku-base-id') || $(this).data('sku-base-id');
  var skuDataL2 = prodcat.data.getSku(skuBaseId);
  var $parentGrid;
  var parentGridOverrideStatus;
  var reorderStatuses = [ // these are the statuses that will trigger a product block to be reordered
    2, // Temporarily Sold Out
    3, // Coming Soon
    7  // Sold Out
  ];
  var reorderTest = function (l2Cache) {
    return _.contains(reorderStatuses, parseInt(l2Cache.INVENTORY_STATUS));
  };
  var reorderSoldOut = function (skuDataL2, el) {
    var $el = $(el);

    if (!_.isArray(skuDataL2)) {
      skuDataL2 = [ skuDataL2 ];
    }

    if (_.every(skuDataL2, reorderTest)) {
      $el.closest('.js-grid-item').appendTo($el.closest('.js-grid-item').parent());
      prodcat.ui.grids.add($el.closest('.js-grid').get(0));
    }
  };

  if (!$addBtn.length) {
    return;
  }

  $parentGrid = $(this).parents('.js-product-grid');
  parentGridOverrideStatus = $parentGrid.data('sold-out-reordering-override');
  // Reordering sold out products (inv. status 7), to be last in display order on MPPs/anywhere they appear in a grid
  if ($parentGrid.is('.js-sold-out-reordering') && parentGridOverrideStatus !== 'off' || parentGridOverrideStatus === 'on') {
    reorderSoldOut(prodcat.data.getProduct($(this).data('product-id')).skus, this);
  }

  var hasWaitlist = Drupal.settings.common && Drupal.settings.common.has_waitlist;
  var waitlist_notify_inv = Drupal.settings.common.waitlist_notify_inv || '3,2,7';
  waitlist_notify_inv = $.map(waitlist_notify_inv.split(','), Number);
  if(!skuBaseId) { //If we have no sku pre selected then disable the AddToCart button
    $waitlistBtn.addClass('hidden');
    $addBtn.removeClass('hidden');
    $addBtn.addClass('button--disabled').data('disabled', true);
  } else if(skuBaseId && skuDataL2) {
    if(skuDataL2.isShoppable) {
      $addBtn.removeClass('button--disabled').data('disabled', false);
      if (hasWaitlist) {
        if ($.inArray(skuDataL2.INVENTORY_STATUS, waitlist_notify_inv) > -1) {
          $(document).trigger('product.waitlist.init', skuDataL2);
        } else {
          $(document).trigger('product.waitlist.reset', skuDataL2);
        }
      }
    } else {
      $addBtn.addClass('button--disabled').data('disabled', true);
      if (hasWaitlist) {
        if ($.inArray(skuDataL2.INVENTORY_STATUS, waitlist_notify_inv) > -1) {
          $(document).trigger('product.waitlist.init', skuDataL2);
        } else {
          $(document).trigger('product.waitlist.reset', skuDataL2);
        }
      }
    }
  }

  $(this).trigger('product.updateInvStatusFinished');
  $(this).trigger('product.setStoreButton');
});

$(document).on('product.updateInvStatusFinished', function() {
  prodcat.ui.grids.clean({
    before: function (grids) {
      _.each(grids, function (grid) {
        var $grid = $(grid),
          hasQuickshop = $grid.hasClass('product-grid--quickshop'),
          // not currently using, but can distinguish on a per grid basis which have inline quickshops
          isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

        if (hasQuickshop && prodcat.ui.quickshop) {
          prodcat.ui.quickshop.reset($grid);
        }
      });
    },
    after: function (grids) {
      _.each(grids, function (grid) {
        var $grid = $(grid),
          hasQuickshop = $grid.hasClass('product-grid--quickshop'),
          // not currently using, but can distinguish on a per grid basis which have inline quickshops
          isInlineQuickshop = $grid.hasClass('js-quickshop-style-inline');

        if (hasQuickshop && prodcat.ui.quickshop) {
          prodcat.ui.quickshop.init($grid);
        }
      });
    }
  });
});

$(document).on('product.quickshopInit', function(e, methods) {
  prodcat.ui.quickshop = _.extend(prodcat.ui.quickshop || {}, methods);
});

$(document).on('product.skuSelect', '.js-product', function(e, skuBaseId) {
  $(this).attr('data-sku-base-id', skuBaseId);
  $('.js-add-to-cart, .js-add-to-bag', this).data('sku-base-id', skuBaseId);
  $('.js-add-to-favorites', this).data('sku-base-id', skuBaseId);

  $(this).trigger('product.updateInvStatus');
});

$(document).on('inv_status_data:updated', '.js-product', function() {
  $(this).trigger('product.updateInvStatus');
});

$(document).on('inv_status_data:finished', function() {
  $(this).trigger('product.updateInvStatusFinished');
});

// SPP links open in new window if spp_in_new_window=true in config
if (Drupal.settings.common && Drupal.settings.common.spp_in_new_window) {
  $(document).on('click', '.js-spp-link', function() {
    $(this).attr('target', '_blank');
  });
} else {
  $(document).on('click', '.js-spp-link', function(e) {

    var product_id = $('.product-full').data('product-id');
    if (!product_id) { // prevent error on non spp pages
      return;
    }
    var currentProduct = prodcat.data.getProduct(product_id);
    var currentProductSkus = currentProduct.skus;
    var linkSku = $(this).parents('.js-product').attr('data-sku-base-id') ? $(this).parents('.js-product').attr('data-sku-base-id') : $(this).parents('.js-product').data('sku-base-id');

    $(currentProductSkus).each(function(){
      if (linkSku == this.SKU_BASE_ID){
        e.preventDefault();
        $('.product-full.js-product').trigger('product.skuSelect', [linkSku]);
        $(window).scrollTop(0);
      }
    });
  });
}

$(document).on('click', '.js-add-to-cart, .js-add-to-bag', function(e) {
  e.preventDefault();
  var $addBtn = $(this);

  if (!!generic.cookie('stores')) {
    window.location.href = storesPath;
    return;
  }

  if ($addBtn.data('disabled')) {
    return;
  }
  var skuBaseId = $addBtn.data('sku-base-id');
  if (!skuBaseId || skuBaseId.length < 1) {
    return;
  }

  // Trigger the button toggle event to show loading message until all this code and RPC call are complete.
  $(document).trigger('addToCart.toggle', [$addBtn]);

  // Account for adding multiple skus to bag
  var args = {};
  skuBaseId = String(skuBaseId);
  if (skuBaseId.indexOf(',') >= 0) {
    // clean the string
    skuBaseId = skuBaseId.replace(/(\s|\r\n|\n|\r)/gm, '');
    args.skuBaseId = skuBaseId.split(',');
    if (skuBaseId.slice(-1) === ',') {
      args.skuBaseId.pop();
    }
  }
  else {
    args.skuBaseId = skuBaseId;
  }

  var quantity = $addBtn.data('qty');
  if (!!quantity) {
    args.quantity = quantity;
  }

  // Replenishment updates when sku is refillable and enable_replenishment=true in config.
  //  (currently applicable only when adding one item at a time)
  if (_.isString(args.skuBaseId) && Drupal.settings.common && Drupal.settings.common.has_replenishment) {
    var skuDataL2 = prodcat.data.getSku(args.skuBaseId);
    if (skuDataL2.REFILLABLE) {
      var frequency = $addBtn.attr('data-replenishment');
      if (!!frequency) {
        args.REPLENISHMENT_FREQ = frequency;
      } else {
        args.REPLENISHMENT_FREQ = 0;
      }
      args.action       = 'add';
      args.itemType     = 'replenishment';
      args.add_to_cart  = 1;
    }
  }

  args.$addBtn = $addBtn;

  // custom sets (soap kits)
  var kitSkus = $addBtn.data('kit-skus');
  if (!!kitSkus) {
    args.COLLECTION_TYPE= 'UKIT';
    args.CAT_BASE_ID = $addBtn.data('kit-cat');
    args.COLLECTION_SUBTYPE = $addBtn.data('kit-type');
    args.COLLECTION_NAME = 'Kit ' + Math.floor((Math.random() * 10000));
    args.SKU_BASE_ID = String(kitSkus).split(',');
    args.INCREMENT = 0;
    args.QTY = 1;
    args.action = 'add,edit,create_kit';
    args._SUBMIT = 'alter_collection';
    args.HAS_MULTIPLE_SKUS = 1;

    generic.jsonrpc.fetch({
      method: 'cart',
      params: [args],
      onSuccess: function(r) {
        var resultData = r.getData();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.success', [resultData]);
      },
      onFailure: function(ss) {
        var errorObjectsArray = ss.getMessagesError();
        $(document).trigger('addToCart.toggle', [args.$addBtn]);
        $(document).trigger('addToCart.failure', [errorObjectsArray]);
      }
    });

    return;
  }

  prodcat.ui.addToCart(args);
});

/*
 ***********************
 * Add-to-favorites button
 ***********************
 */

$(document).on('click', '.js-add-to-favorites', function(e) {
  e.preventDefault();
  var skuBaseId = $(this).attr('data-sku-base-id') || $(this).data('sku-base-id');
  prodcat.ui.addToFavorites({skuBaseId: skuBaseId, $el: $(this)});
});

/*
 ***********************
 * display text fields (price, shade name, etc)
 ***********************
 */
$(document).on('product.skuSelect product.skuDisplay', '.js-product', function(e, skuBaseId) {
  $(this).trigger('product.updateText', [skuBaseId]);
  $(this).trigger('product.updateRoute', [skuBaseId]);
  $(this).trigger('product.setStoreButton');
});

$(document).on('product.setStoreButton', '.js-product', function(e) {
  if (!!generic.cookie('stores')) {
    // all add to bag CTAs change to Find in Store with link to /stores
    var $addBtn = $('.js-add-to-cart, .js-add-to-bag', this);
    $addBtn
        .html(storesButton)
        .attr('href', storesPath)
        .removeClass('button--disabled')
        .data('disabled', false);
  }
});

$(document).on('product.updateText', '.js-product', function(e, skuBaseId) {
  var textFields = [
    { selector: 'js-product-size', field: 'PRODUCT_SIZE' },
    { selector: 'js-product-price', field: 'formattedPrice' },
    { selector: 'js-sku-shade-name', field: 'SHADENAME' },
    { selector: 'js-sku-shade-description', field: 'SHADE_DESCRIPTION' }
  ];
  var skuData = prodcat.data.getSku(skuBaseId);
  var $productEl = $(this);
  var $shade_pickers = $('.js-product-shade-picker', $productEl);

  if (!skuData) {
    return;
  }

  $shade_pickers.each(function(i, obj) {
    var $shade_picker = $(obj);

    var $shades = $('.js-product-shade', $shade_picker);
    var contains_sku = false;

    $shades.each(function(i, obj) {
      var $shade = $(obj);
      var shade_sku = $shade.data('sku');
      if (shade_sku == skuData['SKU_ID']) {
        contains_sku = true;
        return false; // break .each()
      }
    });

    if (!contains_sku) {
      return false; // break .each()
    }

    _.each(textFields, function(el) {
      var $el = $( '.' + el.selector, $productEl);
      if ($el.length < 1) {
        return;
      }
      _.each(textFields, function(el) {
        var $el = $( '.' + el.selector, $shade_picker);
        if ($el.length < 1) {
          return;
        }
        $el.html(skuData[el.field]);
      });
    });
  });

  // Modify the sku label for single size products
  var $sizeLabel = $('.sku-menu__onesize', $productEl);
  // modify to lowercase
  if ($sizeLabel.length) {
    $sizeLabel.text($sizeLabel.text().toLowerCase());
  }
});


// @TODO: This probably needs to be smarter, giving a specific message to the user,
//        if this is something they've already favorited. Also, the already favorited
//        products should have their heart icon filled in on page load, somehow (currently,
//        they're not).
$(document).on('addToWishlist.success addToWishlist.exists', function(event, cr, $el) {
  // Set icon to heart--selected, in the wake of a successful favoriting (even if it was already a favorite)
  // $el.find('.icon').removeClass('icon--heart').addClass('icon--heart--selected');
  $el.find('.js-add-to-favorites-label').hide();
  $el.find('.js-add-to-favorites-label-success').show();
});

// @TODO: This probably needs to be nicer, not just dumping errors to an alert
// box.
$(document).on('addToCart.failure addToWishlist.failure', function(event, errorObjectsArray) {
  // Escape any html in the alert box.
  var prodAddedMsg = $('<div/>').html(errorObjectsArray[0].text).text();
  alert(prodAddedMsg);
});


/**
 * Toggles the visibility of an add-to button, and its sibling loading message
 */
$(document).on('addToCart.toggle', function(event, $addBtn) {
  if (!$addBtn || $addBtn.length < 1) {
    return;
  }

  $addBtn.toggleClass('hidden');

  var loadingDiv = $addBtn.siblings('.js-loading-message').eq(0);

  if (loadingDiv && loadingDiv.length > 0) {
    loadingDiv.toggleClass('hidden');
  }
});

$(document).on('product.updateShadeRoute', '.js-product', function(e, skuBaseId) {
  var $product = $(this);
  var skuData = prodcat.data.getSku(skuBaseId);
  var shadeName = _.result(skuData, 'SHADENAME') || _.result(skuData, 'PRODUCT_SIZE');
  // include reserved characters missing from encodeURIComponent()
  function _fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  if (!!shadeName) {
    var prodData = prodcat.data.getProduct(skuData.PRODUCT_ID);
    var $sppLinks = $('.js-spp-link', $product);
    shadeNameNoSpaces = _fixedEncodeURIComponent(shadeName.toString().split(' ').join('_'));

    $sppLinks.each(function() {
      $(this).attr('href', prodData.url + '#/shade/' + shadeNameNoSpaces);
    });

    if ($product.hasClass('product-full')) {
      var shadeNameWithQueryString = window.location.hash;
      if (shadeNameWithQueryString.indexOf("?") >= 0) {
        shadeNameNoSpaces += '?' + shadeNameWithQueryString.split('?')[1];
      }
      history.replaceState({}, shadeName, '#/shade/' + shadeNameNoSpaces);
    }
  } else {
    location.hash = '';
  }
});

$(document).on('product.updateRoute', '.js-product', function(e, skuBaseId) {
  var $product = $(this);
  var skuData = prodcat.data.getSku(skuBaseId);
  var prodId = !!skuData ? skuData.PRODUCT_ID : $product.attr('data-product-id');
  var prodData = prodcat.data.getProduct(prodId);
  var isProdSized = ((!!prodData && prodData.sized) || ($product.attr('data-product-sized'))) ? 1 : 0;
  var routePrefix = (!isProdSized) ? '#/shade/' : '#/sku/';

  var routeString;
  if (skuData)  {
    if (isProdSized) {
      routeString = _.result(skuData, 'SKU_BASE_ID');
    } else {
      routeString = _.result(skuData, 'SHADENAME');
    }
  } else {
    routeString = $product.attr('data-product-route') || '';
  }

  // include reserved characters missing from encodeURIComponent()
  function _fixedEncodeURIComponent(str) {
    return encodeURIComponent(str).replace(/[!'()*]/g, function(c) {
      return '%' + c.charCodeAt(0).toString(16);
    });
  }

  if (!!routeString) {
    var $sppLinks = $('.js-spp-link', $product);
    routeNoSpaces = _fixedEncodeURIComponent(routeString.toString().split(' ').join('_'));

    $sppLinks.each(function() {
      url = prodData ? prodData.url : $(this).attr('href');
      $(this).attr('href', url + routePrefix + routeNoSpaces);
    });

    if ($product.hasClass('product-full')) {
      var routeWithQueryString = window.location.hash;
      if (routeWithQueryString.indexOf("?") >= 0) {
        routeNoSpaces += '?' + routeWithQueryString.split('?')[1];
      }
      history.replaceState({}, routeString, routePrefix + routeNoSpaces);
    }
  }
});

/**
 * Wire up behavior on page-load according to js- classnames.
 */
var shadeNameNoSpaces;
var routeNoSpaces;
Drupal.behaviors.prodcatUiInit = {
  attach: function(context) {
    $('.js-product', context).trigger('product.init');
  }
};

})(jQuery, window.generic = window.generic || {});

/* global lazySizes */
Drupal.behaviors.gnav = (function($) {
  'use strict';

  var bps = Unison.fetch.all(),
      bp = Unison.fetch.now(),
      _isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10),
      _isIpad = function() {
        return navigator.userAgent.match(/iPad/i);
      },
      closeTimeout = null,

      behavior = {
        attached: false,
        rearranged: false,
        attach: function(context) {
          var signedIn = site.userInfoCookie.getValue('signed_in');
          $('.menu__link', context).off('mouseenter.gnav click.gnav').on('mouseenter.gnav click.gnav', function(event) {
            if (_isDesktop) {
              var href = $(this).attr('href');
              if ((href && href.substr(0, 2) === '/#') || (_isIpad() && $(this).hasClass('menu__link--has-children'))) {
                event.preventDefault();
              }
              if ($(this).hasClass('menu__link--has-children')) {
                $(document).trigger('gnav-util.close');
                $(this).closest('.menu__list').find('.menu__link--is-expanded').removeClass('menu__link--is-expanded');
                $(this).addClass('menu__link--is-expanded');
                var level = parseInt($(this).parents('.menu__primary').attr('data-lvl')) + 1,
                    target = $(this).next().find('.menu--lvl-' + level),
                    className = 'menu__primary--active';

                if (!target.length) {
                  target = $(this).next().find('.gnav-dropdown');
                  className = 'gnav-dropdown--active';
                  $('.gnav-dropdown--active').removeClass('gnav-dropdown--active');
                } else {
                  $('.menu__primary--active').removeClass('menu__primary--active');
                }

                target.addClass(className);
              } else {
                // clean up
                $(this).closest('.menu__item').siblings().find('.menu__link--is-expanded').removeClass('menu__link--is-expanded');
                $(this).closest('.menu__primary').find('.menu__primary--active').removeClass('menu__primary--active');
              }
            } else {
              if (event.type == 'mouseenter') {
                return;
              }

              if ($(this).hasClass('menu__link--has-children')) {
                $(this).toggleClass('menu__link--is-expanded');
                event.preventDefault();
              }

              event.stopPropagation();
            }
          });

          $(document).on('mouseover.gnav', function(event) {
            var $target = $(event.target);

            if ($('.site-header__main').has($target).length) {
              if (closeTimeout) {
                clearTimeout(closeTimeout);
                closeTimeout = false;
              }
            } else {
              behavior.close();
            }
          });

          $('.js-gnav-mobile-trigger').once().on('click touchstart.gnav', function(e) {
            e.preventDefault();
            $('body').toggleClass('mobile-gnav-active');
            if (signedIn === '1') {
              $('.js-nav-account-signout').show();
              $('.js-nav-account-signin').hide();
            } else {
              $('.js-nav-account-signout').hide();
              $('.js-nav-account-signin').show();
            }
          });

          // Event for closing up entire gnav.
          $(document).on('gnav.readyToClose', function() {
            behavior.close();
          });

          $(window).on('resize.gnav', function () {
            var bps = Unison.fetch.all(),
                bp = Unison.fetch.now();

            _isDesktop = parseInt(bp.width, 10) >= parseInt(bps.landscape, 10);
            if (_isDesktop) {
              behavior.close();
            }
          });

          $(window).on('orientationchange.gnav', function () {
            behavior.close();
          });

          $(window).on('scroll', function() {
            if ($(window).scrollTop() > 20) {
              $('.site-header').addClass('site-header--scrolled');
            } else {
              $('.site-header').removeClass('site-header--scrolled');
            }
          });
        },
        close: function() {
          if (closeTimeout) {
            clearTimeout(closeTimeout);
            closeTimeout = false;
          }

          closeTimeout = setTimeout(function() {
            $('.site-header__main').find('.menu__link--is-expanded, .gnav-dropdown--active, .menu__primary--active').removeClass('menu__link--is-expanded gnav-dropdown--active menu__primary--active');
            $('body').removeClass('mobile-gnav-active');
          }, 300);
        }
      };

  return behavior;
})(jQuery);

(function($) {
  'use strict';

  Drupal.behaviors.selectBox = {
    attach: function(context) {
      $('.selectBox:not(.selectBox-attached)', context).each(function() {
        $(this).addClass('selectBox-attached').selectBox({
          mobile: $(this).hasClass('selectBox--yes-even-for-mobile')
        });
      });
    },
    /**
     * Use this instead of direclty invoking $('.selectBox').selectBox('refresh').
     * The plugin doesn't take into account the fact that the selectBox may be
     * disabled for touch and will blow up if it is.
     */
    refresh: function($selects) {
      $selects.each(function() {
        var control = $(this).data('selectBox-control');
        if (control && control.length) {
          $(this).selectBox('refresh');
        }
      });
    }
  };
})(jQuery);

var site = site || {};
site.template = site.template || {};

(function($) {
  Drupal.behaviors.offers = {
    linkedBasedOffer: function() {
      if ($.cookie('offer_info') == null || $.cookie('offer_info').length == 0) {
        return;
      }
      var offer_code = $.cookie('offer_info').split(':')[1];
      site.onLoadRpc.requests.push({
        method: 'offer.linkedOfferState',
        params: [offer_code],
        onSuccess: function(data) {
          var message = data.result.value.linked_offer.offer_message;
          var template = offer_code;
          var rendered = site.template.get({
            name: template,
            data: {message: message}
          });
          generic.overlay.launch({
            content: rendered
          });
        },
        onError: function(data) {}
      });
    },

    attach: function(context, settings) {
      if (this.attached) {
        return;
      }
      this.attached = true;

      this.linkedBasedOffer();
    },

    attached: false
  };
})(jQuery);

(function($) {

  // Basic collapsible-menu behavior. Used in custom markup.
  Drupal.behaviors.collapsibleMenu = {
    attach: function(context) {
      $('.js-collapsible-menu-trigger', context).on('click', function() {
        var $parentGrid = $(this).closest('.js-grid');
        $(this).closest('.collapsible-menu__parent').toggleClass('collapsible-menu__parent--expanded');
        // If we are inside a grid, recalculate heights on click
        if ($parentGrid.length) {
          var $items = $parentGrid.find('.js-grid-item');
          var colCountData = $parentGrid.data('grid-items-per-row');
          site.util.grids.equalHeightsRows($items, colCountData);
        }
      });
    }
  };

  // Basic collapsible-block behavior.
  Drupal.behaviors.collapsibleBlock = {
    attach: function(context) {
      var $block = $('.collapsible-block', context);
      var $trigger = $block.find('.js-collapsible-block-trigger');

      $block.each(function() {
        if ($(this).hasClass('collapsible-block--mobile-collapsed')) {
          $(this).addClass('collapsible-block--alt');
        }

        // Default expansion
        $trigger.on('click', function() {
          $(this).closest('.collapsible-block').toggleClass('collapsible-block--alt');
        });
      });
    }
  };
})(jQuery);
/**
 * Adaptive Placeholders
 */

(function($) {
  'use strict';

  Drupal.behaviors.adaptivePlaceholders = {
    attached: false,

    labelMode: function($input) {
      $input.addClass('js-label-mode');
      $input.removeClass('js-placeholder-mode');
    },

    placeholderMode: function($input) {
      $input.removeClass('js-label-mode');
      $input.addClass('js-placeholder-mode');
    },

    toggleMode: function($input) {
      var self = this;

      if ($input.val() === '') {
        self.placeholderMode($input);
      } else {
        self.labelMode($input);
      }
    },

    bindEvents: function($input) {
      var self = this;

      // swap out placeholder/label classes on focus in or out
      $input.on('focusin', function() {
        self.labelMode($input);
      });

      $input.on('focusout', function() {
        self.toggleMode($input);
      });

      $input.on('change', function() {
        self.toggleMode($input);
      });
    },

    setupDOM: function($inputs) {
      var self = this;

      $inputs.each(function() {
        var $input = $(this);
        if (!$input.hasClass('adpl--processed')) {
          var $label = $input.siblings('label');
          var placeholder = $input.attr('placeholder') || $label.attr('placeholder');

          // input needs a placeholder
          if (!placeholder) {
            return true;
          }

          // if label exists
          if ($label.length > 0) {
            $label.remove(); // hold in space
          } else {
            // if label does not exist, build it
            var id = $input.attr('id');
            if (!!id) {
              $label = $('<label class="label" for="' + id + '">' + placeholder + '</label>');
            } else {
              // if there is no label, and no id on the input, then we cannot proceed
              return true;
            }
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('placeholder')) {
            $label.attr('placeholder', placeholder);
          }

          // ensure that label contains attributes required for display
          if (!$label[0].hasAttribute('alt')) {
            $label.attr('alt', placeholder);
          }

          // ensure that label contains an inner span.label-content wrapping the text
          if ($label.find('span.label-content').length < 1) {
            $label.wrapInner('<span class="label-content"></span>');
          }

          // position the label after the input, required for proper z-index
          $label.insertAfter($input);

          // cleanup inputs
          if ($input.attr('id') == 'google_autocomplete') {
            // google_autcomplete can't have its placeholder attribute removed without
            // it reverting to show the default, so use empty string instead
            $input.attr('placeholder', '');
          } else {
            $input.removeAttr('placeholder');
          }

          // set states, bind events
          self.placeholderMode($input);
          self.bindEvents($input);
          self.toggleMode($input);

          // add CSS class for styling
          $input.addClass('adpl--processed');
        }
      });
    },

    attach: function(context, settings) {
      var self = this;

      if ($('html').hasClass('no-placeholder')) {
        return;
      }

      var $inputs = $('input[type="text"], input[type="email"], input[type="tel"], input[type="number"], input[type="password"], textarea', context).not('.no-adpl');
      self.setupDOM($inputs);
    }
  };
})(jQuery);

/**
 * Clickable blocks - entire block will be clickable
 * Styles are in _helpers.scss under .block--linked
 *
 * Usage:
 * {{#link}} data-clickable="{{link_path}}"{{/link}}
 * {{#url}} data-clickable="{{href}}"{{/url}}
 */

(function($) {
  Drupal.behaviors.clickable = {
    attach: function(context, settings) {
      $('[data-clickable!=""][data-clickable]', context).once('clickable').addClass('block--linked').on('click', function() {
        window.location.href = $(this).data('clickable');
      });
    }
  };
})(jQuery);

Drupal.behaviors.faq = {
  attached: false,
  attach: function(context) {
    if (this.attached) {
      return;
    }
    this.attached = true;
    var activeClass = 'faq-active';
    var isCustomerServicePage = $('.js-cs-page__content-item', context).length > 0;
    $('.js-cs-page__content-item .faq_section', context).first().addClass(activeClass);
    $('.cs-page__content-item.js-cs-page__content-item .faq_section', context).first().removeClass(activeClass);
    var _scrollToAnchorLinkOpened = function() {
      var hash = window.location.hash;
      if (isCustomerServicePage && hash.indexOf('sku') === -1) {
        var $anchorLinkSection = $(hash, context);
        var headerHeight = $('.site-header', context).height();
        $('.cs-section', context).removeClass(activeClass);
        if (hash) {
          if ($anchorLinkSection.length) {
            $(window).scrollTop($anchorLinkSection.offset().top - headerHeight);
            $anchorLinkSection.addClass(activeClass);
          }
        }
      }
    };
    _scrollToAnchorLinkOpened();
    $(window).on('hashchange', function() {
      _scrollToAnchorLinkOpened();
    });

    $('.js-cs-page__content-item').on('click', '.cs-section', function(event) {
      // only add class if we're clicking on element with no active state
      if ($(this).hasClass(activeClass)) {
        $('.cs-section').removeClass(activeClass);
      } else {
        $('.cs-section').removeClass(activeClass);
        $(this).addClass(activeClass);
      }
      Drupal.behaviors.grids.attach();
    });
  }
};

/**
  *Generic back to top functionality
*/

Drupal.behaviors.backToTop = {
  attach: function(context) {
    $('.js-back-to-top').hide();
    $(window).scroll(function() {
      if ($(window).scrollTop() > 100) {
        $('.js-back-to-top').fadeIn(1000);
      } else {
        $('.js-back-to-top').fadeOut(1000);
      }
    });
    $('.js-sticky-back-to-top').click(function() {
      $('html, body').animate({
        scrollTop: 0
      }, 'slow');
      return false;
    });
  }
};

(function($, Drupal, Modernizr) {
  'use strict';
  Drupal.behaviors.anchorLinkHelper = {
    attach: function(context) {
      $('a[name]').each(function() {
        if ($(this).attr('name')) {
          $(this).addClass('section--anchor');
        }
      });

      if (window.location.hash != '') {
        var anchorLink = window.location.hash.replace('#', '').split('/');
        if (anchorLink[1] && $("[name='" + anchorLink[1] + "']").length) {
          if (anchorLink[1] !== 'stores') {
            $(window).scrollTop($("[name='" + anchorLink[1] + "']").position().top);
          }
        }
      }
    }
  };
})(jQuery, Drupal, Modernizr);

(function($) {
  /*
   * HEY. domains hash exists in :
   * site/js/lang_toggle.js
   * site/js/lang_redirect.js
   *
   * THEY MUST STAY THE SAME. chnage one, change the other.
   */
  var domains = {
    // US
    'en_US': '',
    'fr_US': '/fr-e-NA',
    // UF
    'en_UF': '',
    'fr_UF': '/fr-e-UF'
  };

  function getParameterByName(parameterName) {
    var result = null,
        tmp = [];
    var items = location.search.length ? location.search.substr(1).split('&') : false;
    // Shade routing sometimes breaks HTML5 location.search detection
    if (!items.length && location.hash.length && location.hash.split('?').length > 1) {
      items = location.hash.split('?')[1].split('&');
    }
    for (var index = 0; index < items.length; index++) {
      tmp = items[index].split('=');
      if (tmp[0].toLowerCase() === parameterName.toLowerCase()) {
        result = decodeURIComponent(tmp[1]);
      }
    }
    return result;
  }

  var langParam = getParameterByName('locale');
  if (langParam) {
    switchLanguageDrupal(langParam);
  }

  function switchLanguageDrupal(lang) {
    // We're on the wrong domain
    if (lang !== generic.cookie('LOCALE')) {
      var pathSplit = window.location.pathname.split('/');
      var path = pathSplit[1] || '';

      document.cookie = 'LOCALE=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      generic.cookie('LOCALE', lang, {path: '/'});

      // search for existing domain[lang] in path
      var found = _.some(domains, function(domain) {
        if (domain !== '') {
          return domain.indexOf(path) !== -1;
        }
      });

      // strip old domain from path
      if (found) {
        pathSplit.splice(1, 1);
      }

      // reconstruct path
      var fullPath = pathSplit.join('/');

      // empty domains[lang] in certain cases
      if (fullPath.indexOf('.tmpl') > 0 || !domains[lang]) {
        domains[lang] = '';
      }

      var url = window.location.protocol + '//' + window.location.host + domains[lang] + fullPath + window.location.hash;
      window.location.href = url;
    }
  }

  $(document).ready(function() {
    $('.switch-lang-link-ch, .switch-lang-link').on('click', function(e) {
      e.preventDefault();
      return switchLanguageDrupal($(this).attr('data-mp-lang'));
    });
  });
})(jQuery);

var site = site || {};
(function($) {
  Drupal.behaviors.perfumeFragranceFilter = {
    attach: function(context) {
      site.getFilters = function(isMpp) {
        var filters = [];
        var $perfumeFilters = $('.js-perfume-filters');
        var $mppCategoryName = $('.js-mpp__category-name', context);
        var $filterText = $('.js-grid-filter-text', context);
        var $browseAllLink = $('.js-browse-all a', context);
        var $allFpo = $('.js-all-fpo a', context);
        var $filterList = $('.js-filter-list-mpp', context);

        var filter = $perfumeFilters.data('filter-values');
        var showAllText = $perfumeFilters.data('show-all');

        if (isMpp === null) {
          isMpp = false;
        }

        if ($filterList.children().length === 0 && $filterList.length > 0) {
          if ($mppCategoryName.attr('filters_giftfinder')) {
            filter = $mppCategoryName.attr('filters_giftfinder');
            if ($mppCategoryName.data('giftfinderShowAll')) {
              showAllText = $mppCategoryName.data('giftfinderShowAll');
            }
          }

          if ($mppCategoryName.data('subtitleOverride') && $filterText.length > 0) {
            $filterText.addClass('giftfinder');
            $filterText.text($mppCategoryName.data('subtitleOverride'));
          }

          if ($mppCategoryName.data('browseAllOverride')) {
            $browseAllLink.attr('href', $mppCategoryName.data('browseAllOverride'));
          }

          if ($mppCategoryName.data('olfactoryLinkOverride')) {
            $allFpo.attr('href', $mppCategoryName.data('olfactoryLinkOverride'));
          }

          if ($mppCategoryName.data('browseAllStringOverride')) {
            $browseAllLink.text($mppCategoryName.data('browseAllStringOverride'));
          }

          if ($mppCategoryName.data('olfactoryStringOverride')) {
            $allFpo.text($mppCategoryName.data('olfactoryStringOverride'));
          }
        }

        filters = filter.split(',');
        filters.unshift(showAllText);
        $.each(filters, function(index, value) {
          createElements(value.toLowerCase(), isMpp);
        });
      };

      function createElements(value, isMpp) {
        var $wrapper = document.createElement('div');
        $wrapper.setAttribute('id', 'js-menu-filter-content');
        var elementId = value;
        if (!isMpp) {
          $wrapper.setAttribute('class', 'perfume-filter js-menu-filter-content');
          document.getElementById('filter-list').appendChild($wrapper);
        } else if (document.getElementById('filter-list-mpp')) {
          $wrapper.setAttribute('class', 'perfume-filter js-mpp-filter-content');
          document.getElementById('filter-list-mpp').appendChild($wrapper);
          elementId = value.toUpperCase();
        }
        var $checkbox = document.createElement('INPUT');
        $checkbox.setAttribute('type', 'checkbox');
        $checkbox.setAttribute('value', value.replace(/(^[,\s]+)|([\s]+)|([,\s]+$)/g, ''));
        $checkbox.setAttribute('name', value);
        $checkbox.setAttribute('id', elementId);
        var $label = document.createElement('label');
        $label.setAttribute('for', elementId);
        var $labelspan = document.createElement('span');
        $labelspan.setAttribute('class', 'label ' + value);
        $labelspan.innerHTML = value;
        $label.appendChild($labelspan);
        $wrapper.appendChild($checkbox);
        $wrapper.appendChild($label);
      }
    }
  };
})(jQuery);

var site = site || {};
(function($, site) {
  site.videos = {
    open: function(opts) {
      var provider = !_.isEmpty(opts.provider) ? opts.provider : '';
      if (provider === 'vimeo') {
        this.openVimeo(opts);
      } else if (provider === 'youtube') {
        this.openYoutube(opts);
      }
      return opts;
    },

    openVimeo: function(opts) {
      var vimeoId = opts.vimeoId;
      var url = '/fredericmalle-embed/vimeo/' + vimeoId;

      $.ajax(url, {
        success: function(data) {
          var html = data['html'];
          var $parent = $('.js-showVideo');
          var $largeImage = $parent.find('.js-largeImage');
          var $smallImage = $parent.find('.js-image');
          var height = '';
          var width = '';
          if ($parent && $largeImage.length) {
            height = $largeImage.height();
            width = $largeImage.width();
            $parent.empty();
          } else {
            height = $smallImage.height();
            width = $smallImage.width();
          }
          $parent.html(html);
          $parent.find('iframe').attr({ 'allow': 'autoplay', 'height': height, 'width': width });
          $parent.removeClass('js-showVideo max-width');
        },
      });
      return;
    },
    openYoutube: function(opts) {
      var youtubeid = opts.youtubeid;
      var $parent = $('.js-showVideo');
      var $largeImage = $parent.find('.js-largeImage');
      var $smallImage = $parent.find('.js-image');
      var $block_container = $parent.parents('.js-content-block');
      var height = '';
      var width = '';
      var captionLanguage = $block_container.attr('data-caption-language');
      var playerSettings = {
        controls: 1,
        rel: 0,
        showinfo: 0,
        autoplay: 1,
        mute: $block_container.hasClass('video-muted'),
        loop: $block_container.hasClass('video-loop'),
        modestbranding: 0,
      };
      if ($block_container.hasClass('enable-closed-caption')) {
        playerSettings.cc_load_policy = 1;
        playerSettings.cc_lang_pref = captionLanguage;
      }
      if ($parent && $largeImage.length) {
        height = $largeImage.height();
        width = $largeImage.width();
        $parent.empty();
      } else {
        height = $smallImage.height();
        width = $smallImage.width();
      }
      $parent.html('<div id="youtube_player_' + youtubeid + '"></div>');
      var player = new YT.Player('youtube_player_' + youtubeid, {
        height: height,
        width: width,
        videoId: youtubeid,
        playerVars: playerSettings,
        events: {
          'onReady': function(event) {
            event.target.playVideo();
          }
        }
      });
      $parent.removeClass('js-showVideo max-width');
      return;
    }
  };

  var videoBlock = {
    setup: function($elements, context) {
      var $modules = $elements;

      $modules.each(function() {
        var $module = $(this);
        var $play = $('.js-play', $module);
        var provider = $module.data('video-provider');
        $play.once().click(function(event) {
          event.preventDefault();
          var opts = {
            context: context,
            provider: provider
          };
          if (provider === 'vimeo') {
            opts.vimeoId = $module.data('vimeo-id');
          } else if (provider === 'youtube') {
            opts.youtubeid = $module.data('youtube-id');
          }
          site.videos.open(opts);
          return false;
        });
      });
    }
  };

  /**
   * We initialize each type of video separately because we don't know if/when their respective libraries are loaded
   */
  var $elements = $('.js-internetVideo').filter("[data-video-provider='vimeo']");
  if ($elements.length) {
    videoBlock.setup($elements, document);
  }
  var $youtubeElements = $('.video-youtube').filter("[data-video-provider='youtube']");
  if ($youtubeElements.length) {
    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    videoBlock.setup($youtubeElements, document);
  }
})(jQuery, site);

(function($, site, generic) {
  site.onLoadRpc = site.onLoadRpc || {};
  site.onLoadRpc.requests = site.onLoadRpc.requests || [];

  site.onLoadRpc.init = function() {
    // User stuff
    var signedIn = site.userInfoCookie.getValue('signed_in') - 0 === 1;
    if (signedIn) {
      site.onLoadRpc.requests.push({
        method: 'user.fullData',
        params: [{}],
        onSuccess: function(data) {
        // This is for the Phone Order app to recognize values on Drupal pages
          if (!data || !data.result) {
            return;
          }

          var val = data.result.value;

          if ((val.csr_email !== undefined) && (val.csr_email !== null)) {
            $('#csr_header_holder').removeClass('hidden');
          }

          // Set a body class attribute based on user logged in status
          $('body').addClass(val.signed_in === 1 ? 'elc-user-state-logged-in' : 'elc-user-state-anonymous');

          // Populate user data
          $('[data-pg-object="user"] > [data-pg-prop], [data-pg-object="user"][data-pg-prop]').each(function() {
            var $me = $(this);
            $me.html(val[$me.attr('data-pg-prop').toLowerCase()]);
          });

          // generic.user.setUser(val);

          $(document).trigger('user.loaded', val);
        },
        onFailure: function() {}
      });
    } else {
    // generic.user.setUser({});
      $('body').addClass('elc-user-state-anonymous');
      $(document).trigger('user.loaded', {});
    }
  };

  /*
 * site.onLoadRpc.requests - a global array of RPC request objects
 * must be initialized pre-DOM-load and formatted like this:
 * [
 *     {
 *         "method":   "user.json",
 *         "params":   [{}],
 *         "getParams" : function () { return [{}] },  * one of 'params' or 'getParams' is required
 *         "onSuccess" : function () { },
 *         "onFailure" : function () { }
 *     }
 * ]
 */
  site.onLoadRpc.fetch = function() {
    var requests = site.onLoadRpc.requests || [];
    var rLen = requests.length;
    var queryVals = [];

    for (var i = 0, len = rLen; i < len; i++) {
      var postMethod = requests[i].method || 'rpc.form';

      if (typeof requests[i].getParams === 'function') {
        requests[i].params = requests[i].getParams();
      }

      queryVals[i] = {
        method: postMethod,
        params: requests[i].params,
        id: i + 1
      };
    }

    if (!queryVals.length) {
      return;
    }

    var successHandler = function(data) {
      for (var i = 0, len = rLen; i < len; i++) {
        var fn = requests[i].onSuccess;
        if (typeof fn !== 'function') {
          continue;
        }
        fn(data[i]);
      }
    };

    var url = generic.jsonrpc.url || '/rpc/jsonrpc.tmpl';
    var options = {};

    // ELCTWO-571 requires that we pass brand, region, and locale ids to ensure proper responses
    // on the pg side for drupal sites.  To accomplish this we pass 'addl_url_params' within the arguments.
    // This snippets searches for such entries and adds 'em to the request url.
    var url_params = '';
    $(queryVals).each(function() {
      if (this.params[0].url_params) {
        if (this.params[0].url_params.charAt(0) === '&') {
          url_params += this.params[0].url_params;
        } else {
          url_params += '&' + this.params[0].url_params;
        }
      }
    });
    if (url_params !== '') {
      url += '?' + url_params.substring(1);
    }

    options.data = $.param({JSONRPC: JSON.stringify(queryVals)});

    options.type = 'POST';
    options.success = function(data, textStatus, response) {
      successHandler(data, textStatus, response);
    };
    options.error = function(jqXHR, textStatus, errorThrown) {
      console.log(jqXHR, textStatus, errorThrown);
    };
    $.ajax(url, options);
  };

  $(function() {
    $(document).trigger('onLoadRpc.fetch');
    // Set user cookie
    site.userInfoCookie.init();
    site.onLoadRpc.init();
    site.onLoadRpc.fetch();
  });
})(jQuery, window.site || {}, window.generic || {});
